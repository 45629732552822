import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44')
];

export const server_loads = [];

export const dictionary = {
		"/": [13],
		"/(app)/account-creation-pending": [14,[2]],
		"/(app)/activate": [15,[2]],
		"/(app)/benefits": [16,[2]],
		"/(app)/card/[walletId]/add-funds": [19,[2,3]],
		"/(app)/card/[walletId]/dashboard": [20,[2,3]],
		"/(app)/card/[walletId]/(card)/settings": [17,[2,3,4]],
		"/(app)/card/[walletId]/(card)/settings/pin-reveal": [18,[2,3,4]],
		"/(app)/card/[walletId]/transaction/[transactionId]": [21,[2,3]],
		"/(app)/card/[walletId]/withdraw": [22,[2,3]],
		"/(app)/check-account-state": [23,[2]],
		"/(auth)/claim-invite": [38,[12]],
		"/(app)/dashboard": [24,[2]],
		"/(app)/dashboard/[walletId]": [25,[2,5]],
		"/(app)/exchange-money": [26,[2]],
		"/(auth)/forgot-password": [39,[12]],
		"/(app)/new-beneficiary": [27,[2]],
		"/outage": [~44],
		"/(app)/recipients": [28,[2,6]],
		"/(app)/recipients/[beneficiaryId]": [29,[2,6,7]],
		"/(auth)/reset-password": [40,[12]],
		"/(app)/salary-rules": [~30,[2]],
		"/(app)/salary-rules/recipients": [~31,[2]],
		"/(app)/salary-rules/recipients/[recipientId]": [~32,[2],[,8]],
		"/(app)/send": [33,[2,9]],
		"/(app)/send/[beneficiaryId]": [34,[2,9,10],[,,11]],
		"/(app)/send/[beneficiaryId]/fix": [35,[2,9,10],[,,11]],
		"/(auth)/sign-in": [41,[12]],
		"/(auth)/support": [42,[12]],
		"/(auth)/support/unhandled_challenge": [43,[12]],
		"/(app)/update-compliance-details": [36,[2]],
		"/(app)/useful-links": [37,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';